const missionDateModal = document.getElementById('missionDateSelectionModal')
if (missionDateModal) {
  const missionDateForm = missionDateModal.querySelector('form[name="mission_date_selection"]')
  const tokenInput = document.getElementById('mission_date_selection__token')
  const missionDateInput = missionDateForm.querySelector('#mission_date_selection_mission_date')
  missionDateModal.addEventListener('show.bs.modal', (event) => {
    const btnData = event.relatedTarget.dataset
    missionDateForm.action = btnData.missionDateSelectPath
    if (btnData.missionDate !== undefined) {
      missionDateInput.value = btnData.missionDate
    }
    tokenInput.value = btnData.missionToken
  })
}
