const loader = document.querySelector('#loader')
window.addEventListener('pageshow', (event) => {
  if (event.persisted) {
    document.getElementById('loader').classList.remove('show')
  }
})
window.addEventListener('beforeunload', () => {
  document.getElementById('loader').classList.add('show')
})

// Cacher le loader après le chargement complet de la page
window.addEventListener('load', () => {
  document.getElementById('loader').classList.remove('show')
})
